import { toastService } from "../../services/ToastService/toastService";
import { isDevelopment } from "../utilityFunctions/utilityFunctions";
import { errorMessages } from "./errorMessages";

export interface ErrorResponse {
  status: number;
  statusText: string;
  errors: Record<string, string[]>;
}

export interface ResponseData {
  response: {
    ok: boolean;
    status: number;
    statusText: string;
  };
  body?: any;
}

type ErrorCodes = keyof typeof errorMessages;

function formatErrors(
  status: number,
  statusText: string,
  errors: Record<string, string[]>
): string {
  let errorString = `Error ${status}: ${statusText}:`;

  for (const [key, value] of Object.entries(errors)) {
    errorString += ` ${key}: ${value.join(", ")}`;
  }

  return errorString;
}

function isErrorResponse(response: any): response is ErrorResponse {
  return (
    "status" in response && "statusText" in response && "errors" in response
  );
}

export function handleResponse(
  response: ResponseData | ErrorResponse
): Promise<any> {
  return new Promise((resolve, reject) => {
    if (isErrorResponse(response)) {
      const error =
        errorMessages[response.status as unknown as ErrorCodes] ||
        errorMessages.DEFAULT;
      if (isDevelopment()) {
        toastService.showErrorToast(
          `Error ${response.status}: ${response.statusText} - ${error}`
        );
      }
      return reject(error);
    } else {
      const responseData = response.response;
      const data = response.body && JSON.parse(JSON.stringify(response.body));

      if (responseData && !responseData.ok) {
        if ([401, 403].includes(responseData.status)) {
          if (isDevelopment()) {
            toastService.showErrorToast("Unauthorised");
          }
          return reject("Unauthorised");
        }

        if (data.errors) {
          if (isDevelopment()) {
            const formattedError =
              data && data.errors
                ? formatErrors(
                    responseData.status,
                    responseData.statusText,
                    data.errors
                  )
                : data.title;
            toastService.showErrorToast(formattedError);
          }

          return reject(data.errors);
        } else {
          const error =
            errorMessages[data.code as unknown as ErrorCodes] ||
            errorMessages.DEFAULT;
          if (isDevelopment()) {
            toastService.showErrorToast(
              `Error ${responseData.status}: ${responseData.statusText} - ${error}`
            );
          }
          return reject(error);
        }
      } else {
        return resolve(data);
      }
    }
  });
}
