import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { styled } from "@mui/material/styles";

import AlertCircle from "../../../components/AlertCircle";
import NoData from "../../../components/NoData";
import PathCircularProgress from "../../../components/PathCircularProgress";
import { ThreeDotsMenu } from "../../../components/documented/ThreeDotsMenu";
import { selectPatients } from "../../../redux/selectors/patientSelectors";
import { selectAccountId } from "../../../redux/selectors/accountSelectors";
import { useAppThunkDispatch } from "../../../redux/configureStore";
import { getPatients } from "../../../redux/actions/healthcareProfessionalActions";
import { Patient } from "../../../model/patient/patient";
import { healthcareProfessionalService } from "../../../services/healthcareProfessionalService";
import { pluralize } from "../../../helpers/utilityFunctions/utilityFunctions";
import { useClientTheme } from "../../../helpers/hooks/useClientTheme";
import { clearSelectedDailyMetrics } from "../../../redux/actions/dailyMetricActions";
import {
  getComparator,
  Order,
  StyledTableCell,
  StyledTableRow,
} from "../TableComponents";
import { ComplianceMode } from "../../../model/compliance/ComplianceMode";
import calculateFill from "../../../helpers/calculateFill/calculateFill";

const TableDetailContainer = styled(Box)(() => ({
  height: "fit-content",
  width: "100%",
  padding: "10px 20px 10px 20px",
}));

export interface HeadCell {
  id: keyof Patient;
  label: string;
  numeric: boolean;
  toolTip?: any;
  visible: boolean;
}

export interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Patient
  ) => void;
  order: Order;
  orderBy: string;
}

function renderComplianceTooltip(theme: any, fill?: string) {
  const complianceCircles = [
    {
      fill: theme.palette.complianceUndefined.main,
      text: "Compliance undefined",
    },
    {
      fill: theme.palette.complianceNotAchievable.main,
      text: "16 of 30 days cannot or have not been achieved",
    },
    {
      fill: theme.palette.complianceAtRisk.main,
      text: "At risk of not completing 16 of 30 days",
    },
    {
      fill: theme.palette.complianceOnTrack.main,
      text: "On track to complete 16 of 30 days",
    },
    { fill: theme.palette.complianceCompleted.main, text: "Done (above 16)" },
  ];

  if (fill) {
    const circle = complianceCircles.find((circle) => circle.fill === fill);
    return circle ? circle.text : "";
  }

  return (
    <Grid container>
      <Grid item xs={12} mt={0.5}>
        Number of days of use in the current 30 days period. One day is defined
        by 30 mins or more of use.
      </Grid>
      {complianceCircles.map((circle, index) => (
        <React.Fragment key={index}>
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "5px 0",
            }}
          >
            <AlertCircle fill={circle.fill} />
          </Grid>
          <Grid item xs={7} style={{ margin: "5px 0" }}>
            {circle.text}
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
}

function AuthorisedPatientsTableHead(props: EnhancedTableProps) {
  const { theme } = useClientTheme();
  const { order, orderBy, onRequestSort } = props;
  const complianceMode = process.env
    .REACT_APP_COMPLIANCE_MODE as ComplianceMode;

  const createSortHandler =
    (property: keyof Patient) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const headCells: readonly HeadCell[] = [
    {
      id: "firstName",
      numeric: false,
      label: "First Name",
      visible: true,
    },
    {
      id: "lastName",
      numeric: false,
      label: "Last Name",
      visible: true,
    },
    {
      id: "currentCompliancePeriod",
      numeric: true,
      label: "Days of use current period",
      toolTip: renderComplianceTooltip(theme),
      visible: complianceMode === ComplianceMode.CurrentPeriod,
    },
    {
      id: "totalDaysOfInsoleUsageLastMonth",
      numeric: true,
      label: "Days of use previous month",
      toolTip:
        "Number of days of use in the previous calendar month. One day is defined by 30 mins or more of use.",
      visible: complianceMode === ComplianceMode.CurrentAndLastMonth,
    },
    {
      id: "totalDaysOfInsoleUsageCurrentMonth",
      numeric: true,
      label: "Days of use current month",
      toolTip:
        "Number of days of use in the current calendar month. One day is defined by 30 mins or more of use.",
      visible: complianceMode === ComplianceMode.CurrentAndLastMonth,
    },
    {
      id: "insoleLastUsageDate",
      numeric: false,
      label: "Last day of use",
      visible: true,
    },
    {
      id: "email",
      numeric: false,
      label: "Email",
      visible: true,
    },
    {
      id: "alertLevel",
      numeric: true,
      label: "Alert",
      toolTip:
        "A red dot appears if there has been one or more pressure or temperature alerts in the last 3 days. The dot’s color is linked to the highest alert level.",
      visible: true,
    },
    {
      id: "verified",
      numeric: false,
      label: `${theme.productNames.insoles} user since`,
      visible: true,
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells
          .filter((headCell) => headCell.visible)
          .map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align={headCell.id !== "alertLevel" ? "left" : "center"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <Tooltip title={headCell.toolTip || ""}>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </Tooltip>
            </StyledTableCell>
          ))}
        <StyledTableCell align="left">User Actions</StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

interface Props {
  isLoading: boolean;
}

export const AuthorisedPatientsTable: React.FC<Props> = ({ isLoading }) => {
  const patients = useSelector(selectPatients);
  const accountId = useSelector(selectAccountId);
  const navigate = useNavigate();
  const dispatch = useAppThunkDispatch();
  const { theme } = useClientTheme();
  const complianceMode = process.env
    .REACT_APP_COMPLIANCE_MODE as ComplianceMode;

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Patient>("lastName");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Patient
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (
    event: React.MouseEvent<unknown>,
    accountGuid: string
  ) => {
    const selectedPatientData = patients.find(
      (data) => data.accountGuid === accountGuid
    );
    window.localStorage.setItem(
      "selectedPatientFromPatientsTable",
      JSON.stringify(selectedPatientData)
    );
    dispatch(clearSelectedDailyMetrics());
    navigate(`/dashboard/patients/${accountGuid}`);
  };

  const handleRemovePatient = (patientId: string) => {
    healthcareProfessionalService
      .revokePatient(accountId, patientId)
      .then((response: any) => {
        dispatch(getPatients(accountId, true));
      })
      .catch((error: any) => {});
  };

  const RenderAlertColour = (level: number) => {
    switch (level) {
      case 0:
        return "transparent";
      case 1:
        return theme.palette.alertLevel1.main;
      case 2:
        return theme.palette.alertLevel2.main;
      case 3:
        return theme.palette.alertLevel3.main;
      default:
        return "transparent";
    }
  };
  return (
    <TableDetailContainer>
      <Typography
        sx={{ padding: "5px 0px 5px 0px" }}
        variant="h5"
        gutterBottom
        component="h2"
      >
        Your Patients
      </Typography>
      {!isLoading ? (
        patients.length ? (
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer sx={{ maxHeight: 980, minWidth: 750 }}>
              <Table stickyHeader aria-labelledby="authorised-patients-table">
                <AuthorisedPatientsTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {patients
                    .slice()
                    .sort(getComparator(order, orderBy))
                    .map((patient) => (
                      <StyledTableRow
                        hover
                        tabIndex={-1}
                        key={patient.accountGuid}
                      >
                        <StyledTableCell
                          align="left"
                          onClick={(event) =>
                            handleClick(event, patient.accountGuid)
                          }
                        >
                          {patient.firstName}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          onClick={(event) =>
                            handleClick(event, patient.accountGuid)
                          }
                        >
                          {patient.lastName}
                        </StyledTableCell>

                        {complianceMode === ComplianceMode.CurrentPeriod && (
                          <StyledTableCell
                            style={{ width: "300px" }}
                            align="left"
                            onClick={(event) =>
                              handleClick(event, patient.accountGuid)
                            }
                          >
                            {patient.currentCompliancePeriod ? (
                              <Grid container>
                                <Grid
                                  item
                                  xs={3}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Tooltip
                                    title={renderComplianceTooltip(
                                      theme,
                                      calculateFill(
                                        theme,
                                        patient.currentCompliancePeriod
                                          .daysOfUse,
                                        patient.currentCompliancePeriod
                                          .daysTotal
                                      )
                                    )}
                                  >
                                    <AlertCircle
                                      data-testid="compliance-circle"
                                      fill={calculateFill(
                                        theme,
                                        patient.currentCompliancePeriod
                                          .daysOfUse,
                                        patient.currentCompliancePeriod
                                          .daysTotal
                                      )}
                                    />
                                  </Tooltip>
                                </Grid>
                                <Grid item xs={7}>
                                  {`${patient.currentCompliancePeriod?.daysOfUse} days of ${patient.currentCompliancePeriod.daysTotal}`}
                                </Grid>
                                <Grid item xs={12} mt={0.5}>
                                  {`Started on ${new Date(
                                    patient.currentCompliancePeriod.startTime
                                  ).toDateString()}`}
                                </Grid>
                              </Grid>
                            ) : null}
                          </StyledTableCell>
                        )}

                        {complianceMode ===
                          ComplianceMode.CurrentAndLastMonth && (
                          <>
                            <StyledTableCell
                              align="left"
                              onClick={(event) =>
                                handleClick(event, patient.accountGuid)
                              }
                            >
                              {pluralize(
                                patient.totalDaysOfInsoleUsageLastMonth,
                                "day"
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              onClick={(event) =>
                                handleClick(event, patient.accountGuid)
                              }
                            >
                              {pluralize(
                                patient.totalDaysOfInsoleUsageCurrentMonth,
                                "day"
                              )}
                            </StyledTableCell>
                          </>
                        )}

                        <StyledTableCell
                          align="left"
                          onClick={(event) =>
                            handleClick(event, patient.accountGuid)
                          }
                        >
                          {new Date(patient.insoleLastUsageDate).toDateString()}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          onClick={(event) =>
                            handleClick(event, patient.accountGuid)
                          }
                        >
                          {patient.email}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          onClick={(event) =>
                            handleClick(event, patient.accountGuid)
                          }
                        >
                          <Box display="flex" justifyContent="center" mr="18px">
                            <Tooltip title="This patient had one or more temperature/pressure alerts in the past 3 days">
                              <AlertCircle
                                fill={RenderAlertColour(patient.alertLevel)}
                              />
                            </Tooltip>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          onClick={(event) =>
                            handleClick(event, patient.accountGuid)
                          }
                        >
                          {new Date(patient.verified).toDateString()}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <ThreeDotsMenu
                            data={patient.accountGuid}
                            handleAction={handleRemovePatient}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        ) : (
          <NoData message="You have no authorised patients. Click the + button above to add a patient" />
        )
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          height="100px"
          justifyContent="center"
          data-testid="loading-indicator"
        >
          <PathCircularProgress />
        </Box>
      )}
    </TableDetailContainer>
  );
};

export default AuthorisedPatientsTable;
