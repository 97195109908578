import React from "react";
import { Grid } from "@mui/material";
import TimeTakenTableComponent from "./TimeTakenTableComponent";
import { RadialIndicator } from "../../../../components/documented/RadialIndicator";
import { DropShadowCard } from "../../../../components/documented/DropShadowCard";
import getRadialConfig from "../../../../components/documented/RadialIndicator/radialConfig";
import { ActiveType } from "../../../../model/activeType";

const TimedUpAndGo: React.FC = () => {
  return (
    <Grid container direction="row" mt={1} spacing={12} alignItems="stretch">
      <Grid item xs={8}>
        <DropShadowCard title="TUG Score" icon="_TUGT" shadow={3}>
          <RadialIndicator
            {...getRadialConfig(ActiveType._TUG)}
            size={200}
            value={20}
            unit="m/s"
          />
        </DropShadowCard>
      </Grid>
      <Grid item>
        <TimeTakenTableComponent />
      </Grid>
    </Grid>
  );
};

export default TimedUpAndGo;
