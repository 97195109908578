import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Box, IconButton, Theme } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import IconLoader from "../IconLoader";
import { HeatmapTransforms } from "../Heatmap/heatmapTransforms";
import { PassiveParameterType } from "../../model/passive/passiveParameterType";
import Heatmap from "../Heatmap/Heatmap";
import { HeatmapData } from "../../model/passive/heatmapData";
import { Temperature } from "../../model/temperature";
import heatmapBackground from "../../assets/images/sensorInsoles.png";
import heatmapOverlay from "../../assets/images/Mask_Lmdpi.png";
import { HeatmapSizeEnum } from "../Heatmap/HeatmapSizeEnum";

const useStyles = makeStyles<Theme, AlertStyleProps>((theme: Theme) => ({
  card: {
    height: "381px",
    width: "254px",
    display: "flex",
    position: "relative",
    flexDirection: "column",
    border: "2px solid",

    borderColor: ({ isAlertStyle, showingInfo }) =>
      `${
        showingInfo
          ? theme.palette.primary.main
          : isAlertStyle
          ? "#D90B0A"
          : "#197F2B"
      }`,
    borderRadius: "10px !important",
  },
  cardMedia: {
    display: "flex !important",
    alignItems: "center",
    marginTop: 15,
    flexDirection: "column",
  },
  cardContent: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 0px 0px 16px !important",
  },
  infoIcon: {
    height: 28,
    width: 28,
    color: theme.palette.primary.main,
  },
  expandedInfoIcon: {
    color: "white",
  },
  atRiskArea: {
    display: "flex",

    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  circle: {
    display: "flex",
    alignItems: "center",
    height: "28px",
    width: "28px",
    borderRadius: "50px",
    backgroundColor: `${theme.palette.error.main} !important`,
    color: theme.palette.info.main,
  },
  popOver: {
    position: "absolute",
    zIndex: 2,
    top: 0,
    right: 0,
    height: 0,
    width: 0,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "6px",
    transform: "rotate(0deg)",
    transition: "all 250ms ease-in-out",
  },
  popOverEntering: {
    height: "381px",
    width: "254px",
    transition: "all 250ms ease-in-out",
    transform: "rotate(0deg)",
  },
  popOverContent: {
    display: "flex",
    flexDirection: "row",
    margin: "40px 0px 0px 16px",
    color: "white",
  },
}));

interface AlertStyleProps {
  isAlertStyle: boolean | undefined;
  showingInfo: boolean | undefined;
}

interface IAlertCardProps {
  onClick: (arg: string) => void;
  isAlert?: boolean;
  parameterType: PassiveParameterType;
  popoverContent: string;
  heatmapData: HeatmapData;
  unit?: Temperature;
}

const AlertCard: React.FC<IAlertCardProps> = ({
  onClick,
  isAlert,
  parameterType,
  popoverContent,
  heatmapData,
  unit,
}: IAlertCardProps) => {
  const [showInfo, setShowInfo] = useState<boolean>(false);

  const styleProps: AlertStyleProps = {
    isAlertStyle: isAlert,
    showingInfo: showInfo,
  };

  const classes = useStyles(styleProps);

  const defaultTransforms: HeatmapTransforms = {
    scaleX: 0,
    scaleY: 0,
    translateX: 0,
    translateY: 0,
  };

  const pressureTransforms: HeatmapTransforms = {
    scaleX: 0.44,
    scaleY: 0.49,
    translateX: -40,
    translateY: -60,
  };

  const temperatureTransforms: HeatmapTransforms = {
    scaleX: 0.41,
    scaleY: 0.49,
    translateX: -50,
    translateY: -40,
  };

  const selectTransforms = (type: PassiveParameterType) => {
    switch (type) {
      case PassiveParameterType.AlarmPressure:
        return pressureTransforms;
      case PassiveParameterType.AlarmTemperature:
        return temperatureTransforms;
      default:
        return defaultTransforms;
    }
  };

  const CardHeader = () => {
    return (
      <Box
        sx={{
          display: "-webkit-flex",
          flexDirection: "row",
          WebkitJustifyContent: "right",
        }}
      >
        <Box
          className={clsx(classes.popOver, {
            [classes.popOverEntering]: showInfo,
          })}
        >
          <Box className={classes.popOverContent}>
            <IconLoader name={parameterType} fill="white" />
            <Typography
              sx={{
                ml: 2,
              }}
              variant="h5"
              component="h2"
            >
              <strong>{parameterType} Distribution</strong>
            </Typography>
          </Box>
          <Typography
            sx={{
              mt: 2,
              ml: 2,
              mr: 2,
              color: "white",
            }}
            variant="body1"
          >
            {popoverContent}
          </Typography>
        </Box>

        <IconButton
          sx={{ ml: 1, display: "inline-bloc", zIndex: 2 }}
          onClick={() => setShowInfo(!showInfo)}
        >
          <InfoIcon
            className={clsx(classes.infoIcon, {
              [classes.expandedInfoIcon]: showInfo,
            })}
          />
        </IconButton>
      </Box>
    );
  };

  return (
    <Card className={classes.card}>
      {CardHeader()}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          cursor: "pointer",
        }}
        onClick={() => onClick(parameterType)}
      >
        <CardContent className={classes.cardContent}>
          <IconLoader name={parameterType} />
          <Typography
            sx={{
              ml: 2,
            }}
            variant="h5"
            component="h2"
          >
            <strong>{parameterType} Distribution</strong>
          </Typography>
        </CardContent>

        <CardMedia className={classes.cardMedia}>
          <Heatmap
            height={130}
            width={130}
            magnitude={HeatmapSizeEnum.Small}
            data={heatmapData}
            type={parameterType}
            background={heatmapBackground}
            overlay={heatmapOverlay}
            transforms={selectTransforms(parameterType)}
            unit={unit}
          />
          {isAlert ? (
            <Box className={classes.atRiskArea}>
              <div className={classes.circle}>
                <WarningAmberIcon
                  sx={{
                    height: 18,
                    color: "white",
                    ml: "2px",
                  }}
                />
              </div>
              <Typography
                sx={{
                  mt: 1,
                }}
              >
                <strong>At risk</strong>
              </Typography>
              <Typography>{parameterType}.</Typography>
            </Box>
          ) : (
            <Typography
              sx={{
                mt: 7,
                wordWrap: "break-word",
              }}
              align="center"
            >
              {parameterType === PassiveParameterType.AlarmPressure
                ? "No significant pressure"
                : "No significant temperature differences"}
            </Typography>
          )}
        </CardMedia>
      </Box>
    </Card>
  );
};

export default AlertCard;
