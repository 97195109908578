const complianceTarget = 16;
const compliancePeriodDuration = 30;
//const complianceLimit1 = 3;
//const complianceLimit2 = 23;
const complianceRedOrangeThreshold = 20;
const complianceLimitFactor = 0.8;

function calculateFill(
  theme: any,
  x: number | undefined,
  y: number | undefined
): string {
  if (x === undefined || y === undefined || x > y) {
    return theme.palette.complianceUndefined.main; // or transparent if possible
  } else if (compliancePeriodDuration - y + x - complianceTarget < 0) {
    return theme.palette.complianceNotAchievable.main; //16 of 30 days cannot or have not been achieved
  } else if (x >= complianceTarget) {
    return theme.palette.complianceCompleted.main; // Done (above 16)
  } else {
    if (
      Math.floor((compliancePeriodDuration - y) * complianceLimitFactor + x) >
      complianceRedOrangeThreshold
    ) {
      return theme.palette.complianceOnTrack.main; // On track to complete 16 of 30 days
    } else {
      return theme.palette.complianceAtRisk.main; //At risk of not completing 16 of 30 days
    }
  }
}

export default calculateFill;
