import { styled } from "@mui/material/styles";
import { shouldForwardProp } from "../helpers/utilityFunctions/utilityFunctions";

type IAlertCircleStyleProps = {
  fill: string;
  "data-testid"?: string;
};

const AlertCircle = styled("div", {
  shouldForwardProp: (prop) =>
    shouldForwardProp<IAlertCircleStyleProps>(["fill"], prop),
})<IAlertCircleStyleProps>(({ fill }) => ({
  display: "flex",
  width: "20px",
  height: "20px",
  backgroundColor: fill,
  borderRadius: "50%",
  margin: "auto", // Horizontally center
}));

export default AlertCircle;
