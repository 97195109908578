import { fetchJSON } from "../modules/refreshFetch";
import { fetch } from "../helpers/api/api";
import { handleResponse } from "../helpers/api/handleResponse";
import { RedeemRequest } from "../components/Account/RedeemRequest";
import { InviteRequest } from "../model/inviteRequest";
import { ResendEmailRequest } from "../model/resendEmailRequest";

const baseUrl = `${process.env.REACT_APP_API_URL}/invite`;

export const inviteService = {
  getInviteDetails,
  redeemInvite,
  inviteUser,
  getAllActive,
  deleteInvite,
  resendInviteRequest,
};

function getInviteDetails(inviteGuid: string) {
  return fetchJSON(`${baseUrl}/get-invite/${inviteGuid}`).then(
    (response: any) => handleResponse(response)
  );
}

function redeemInvite(
  invitationCode: string,
  firstName: string,
  lastName: string,
  email: string,
  speciality: string,
  password: string,
  confirmPassword: string
) {
  const request: RedeemRequest = {
    invitationCode: invitationCode,
    firstName: firstName,
    lastName: lastName,
    email: email,
    speciality: speciality,
    password: password,
    confirmPassword: confirmPassword,
  };
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(request),
  };
  return fetchJSON(`${baseUrl}/redeem`, requestOptions)
    .then((response: any) => handleResponse(response))
    .catch((error: any) =>
      handleResponse({ response: error.response, body: error.body })
    );
}

function inviteUser(data: InviteRequest) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
  };
  return fetch(`${baseUrl}/invite-user`, requestOptions)
    .then((response: any) => handleResponse(response))
    .catch((error: any) =>
      handleResponse({ response: error.response, body: error.body })
    );
}

function getAllActive() {
  return fetch(`${baseUrl}/get-all-active`)
    .then((response: any) => handleResponse(response))
    .catch((error: any) =>
      handleResponse({ response: error.response, body: error.body })
    );
}

function deleteInvite(inviteCodeGuid: string) {
  const requestOptions = {
    method: "POST",
  };
  return fetch(`${baseUrl}/delete/${inviteCodeGuid}`, requestOptions)
    .then((response: any) => handleResponse(response))
    .catch((error: any) =>
      handleResponse({ response: error.response, body: error.body })
    );
}

function resendInviteRequest(email: string) {
  const request: ResendEmailRequest = { email: email };
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(request),
  };
  return fetchJSON(`${baseUrl}/resend-invite-email`, requestOptions)
    .then((response: any) => handleResponse(response))
    .catch((error: any) =>
      handleResponse({ response: error.response, body: error.body })
    );
}
