import { ActiveType } from "../../../model/activeType";
import { PassiveParameterTypeEnum } from "../../../model/passive/enum/passiveParameterTypeEnum";
import { AngleDescription } from "./circularGeometry";
import { ColorStop } from "./colorStop";

// See this spreadsheet to understand the color code a bit more
// Compliance speed dial logic https://docs.google.com/spreadsheets/d/1eKWipMxB8nVbYRO3t7mboGh6F6oepKhKZGfMvo7hKHg/edit?gid=1090252165#gid=1090252165
const complianceTarget = 16;
const compliancePeriodDuration = 30;
const complianceLimit1 = 3;
const complianceLimit2 = 23;
const complianceRedOrangeThreshold = 20;
const complianceLimitFactor = 0.8;

export const defaultConfig: RadialConfig = {
  type: PassiveParameterTypeEnum.AverageCadence,
  minValue: 0,
  maxValue: 0,
  startAngle: 0,
  endAngle: 0,
  angleType: { direction: "cw", axis: "-y" },
  range: [0],
  colorStops: [{ color: "#000000", position: 0 }],
};

interface RadialConfig {
  type: PassiveParameterTypeEnum | ActiveType;
  minValue: number;
  maxValue: number;
  startAngle: number;
  endAngle: number;
  angleType: AngleDescription;
  range: Array<number>;
  colorStops: Array<ColorStop>;
  isAnimated?: boolean;
}

//default rainbow look, used as default if not defined
const defaultColorStops = [
  { color: "#197F2B", position: 0 },
  { color: "#197F2B", position: 25 },
  { color: "#E46419", position: 50 },
  { color: "#D90B0A", position: 75 },
  { color: "#D90B0A", position: 100 },
];

const radialConfigs: Array<RadialConfig> = [
  {
    type: PassiveParameterTypeEnum.AverageWalkingSpeed,
    minValue: 0,
    maxValue: 2.5,
    startAngle: 40,
    endAngle: 320,
    angleType: {
      direction: "cw",
      axis: "-y",
    },
    range: [0, 1.25, 2.5],
    colorStops: [
      { color: "#D90B0A", position: 0 },
      {
        color: "#D90B0A",
        position: ((1.3 - 0) / (2.5 - 0)) * 100 - 2 * 25 + 10,
      },
      { color: "#E46419", position: ((1.3 - 0) / (2.5 - 0)) * 100 - 25 + 10 },
      { color: "#197F2B", position: ((1.3 - 0) / (2.5 - 0)) * 100 + 10 },
      { color: "#197F2B", position: 100 },
    ],
  },
  {
    type: PassiveParameterTypeEnum.AverageCadence,
    minValue: 70,
    maxValue: 150,
    startAngle: 40,
    endAngle: 320,
    angleType: {
      direction: "cw",
      axis: "-y",
    },
    range: [70, 110, 150],
    colorStops: [
      { color: "#D90B0A", position: 0 },
      {
        color: "#D90B0A",
        position: ((115 - 70) / (150 - 70)) * 100 - 2 * 25 + 10,
      },
      { color: "#E46419", position: ((115 - 70) / (150 - 70)) * 100 - 25 + 10 },
      { color: "#197F2B", position: ((115 - 70) / (150 - 70)) * 100 + 10 },
      { color: "#197F2B", position: 100 },
    ],
  },
  {
    type: PassiveParameterTypeEnum.Compliance,
    minValue: 0,
    maxValue: 20,
    startAngle: 40,
    endAngle: 320,
    angleType: {
      direction: "cw",
      axis: "-y",
    },
    range: [0, 16, 20],
    colorStops: defaultColorStops,
  },
  {
    type: ActiveType._TUG,
    minValue: 0,
    maxValue: 100,
    startAngle: 40,
    endAngle: 320,
    angleType: {
      direction: "cw",
      axis: "-y",
    },
    range: [0, 10, 20, 30, 40, 50, 50, 60, 70, 80, 90, 100],
    colorStops: defaultColorStops,
  },
  {
    type: ActiveType._10MWT,
    minValue: 0,
    maxValue: 2,
    startAngle: 40,
    endAngle: 320,
    angleType: {
      direction: "cw",
      axis: "-y",
    },
    range: [0, 1, 2],
    colorStops: defaultColorStops,
  },
];

const getColorStops = (maxValue: number) => {
  if (maxValue === compliancePeriodDuration) {
    return [
      { color: "#808080", position: 0 },
      {
        color: "#808080",
        position: ((complianceTarget - 1) / compliancePeriodDuration) * 100,
      },
      {
        color: "#197F2B",
        position: (complianceTarget / compliancePeriodDuration) * 100,
      },
      { color: "#197F2B", position: 100 },
    ];
  } else if (
    maxValue > compliancePeriodDuration - complianceTarget &&
    maxValue < complianceLimit2
  ) {
    return [
      { color: "#808080", position: 0 },
      {
        color: "#808080",
        position:
          ((Math.floor(
            maxValue - (compliancePeriodDuration - complianceTarget)
          ) -
            1) /
            maxValue) *
          100,
      },
      {
        color: "#D90B0A",
        position:
          (Math.floor(
            maxValue - (compliancePeriodDuration - complianceTarget)
          ) /
            maxValue) *
          100,
      },
      {
        color: "#D90B0A",
        position:
          ((complianceRedOrangeThreshold -
            Math.floor(
              complianceLimitFactor * (compliancePeriodDuration - maxValue)
            )) /
            maxValue) *
          100,
      },
      {
        color: "#E46419",
        position:
          ((complianceRedOrangeThreshold -
            Math.floor(
              complianceLimitFactor * (compliancePeriodDuration - maxValue)
            ) +
            1) /
            maxValue) *
          100,
      },
      {
        color: "#E46419",
        position: ((complianceTarget - 1) / maxValue) * 100,
      },
      { color: "#197F2B", position: (complianceTarget / maxValue) * 100 },
      { color: "#197F2B", position: 100 },
    ];
  } else if (maxValue >= complianceLimit2) {
    return [
      { color: "#808080", position: 0 },
      {
        color: "#808080",
        position:
          ((maxValue - (compliancePeriodDuration - complianceTarget) - 1) /
            maxValue) *
          100,
      },
      {
        color: "#D90B0A",
        position:
          ((maxValue - (compliancePeriodDuration - complianceTarget)) /
            maxValue) *
          100,
      },
      { color: "#D90B0A", position: ((complianceTarget - 1) / maxValue) * 100 },
      { color: "#197F2B", position: (complianceTarget / maxValue) * 100 },
      { color: "#197F2B", position: 100 },
    ];
  } else if (maxValue <= complianceLimit1) {
    return [
      { color: "#E46419", position: 0 },
      { color: "#E46419", position: 100 },
    ];
  } else {
    return [
      { color: "#D90B0A", position: 0 },
      {
        color: "#D90B0A",
        position:
          ((complianceRedOrangeThreshold -
            Math.floor(
              complianceLimitFactor * (compliancePeriodDuration - maxValue)
            )) /
            maxValue) *
          100,
      },
      {
        color: "#E46419",
        position:
          ((complianceRedOrangeThreshold -
            Math.floor(
              complianceLimitFactor * (compliancePeriodDuration - maxValue)
            ) +
            1) /
            maxValue) *
          100,
      },
      { color: "#E46419", position: 100 },
    ];
  }
};

const getRadialConfig = (
  type: PassiveParameterTypeEnum | ActiveType,
  newMaxValue?: number
): RadialConfig => {
  const config =
    radialConfigs.find((config) => config.type === type) || defaultConfig;

  let colorStops: Array<ColorStop> = config.colorStops;
  let maxValue = config.maxValue;
  let range = [...config.range];

  if (newMaxValue !== undefined) {
    colorStops = getColorStops(newMaxValue);
    maxValue = newMaxValue;
    range[range.length - 1] = newMaxValue;
    range[Math.floor(range.length / 2)] =
      newMaxValue >= 16 ? 16 : Math.ceil(newMaxValue / 2);
  }

  return { ...config, colorStops, maxValue, range };
};

export default getRadialConfig;
