import { fetch } from "../../helpers/api/api";
import { handleResponse } from "../../helpers/api/handleResponse";

const baseUrl = `${process.env.REACT_APP_API_URL}/passive`;

export const alertService = {
  getAlertDetailByType,
};

//naming of this is confusing - alert data is on the "passive parameter" controller, which is why it needs /passive in the URL - this may be refactrored at some point
function getAlertDetailByType(patientId: string, type: any) {
  return fetch(`${baseUrl}/latest/alerts/${patientId}/${type}`)
    .then((response: any) => handleResponse(response))
    .catch((error: any) =>
      handleResponse({ response: error.response, body: error.body })
    );
}
