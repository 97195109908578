import React from "react";
import QRCode from "react-qr-code";
import { Box, Divider, Grid, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FORM_STATUS } from "../../../model/formStatus";
import { selectAccountId } from "../../../redux/selectors/accountSelectors";
import { useSelector } from "react-redux";
import { useAppThunkDispatch } from "../../../redux/configureStore";
import { getPatients } from "../../../redux/actions/healthcareProfessionalActions";
import { healthcareProfessionalService } from "../../../services/healthcareProfessionalService";

import { useClientTheme } from "../../../helpers/hooks/useClientTheme";
import BackButton from "../../../components/BackButton";

const defaultState = {
  accountId: "",
};

export const AddPatientPage: React.FC = () => {
  const { theme } = useClientTheme();
  const [formData, setFormData] = React.useState(defaultState);
  const [status, setStatus] = React.useState(FORM_STATUS.IDLE);
  const [touched, setTouched] = React.useState(defaultState);
  const [statusMessage, setStatusMessage] = React.useState("");
  const [requestComplete, setRequestComplete] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const accountId = useSelector(selectAccountId);
  const dispatch = useAppThunkDispatch();

  function handleChange(e: any) {
    e.persist();
    setStatusMessage("");
    setFormData((curFormData) => {
      return {
        ...curFormData,
        [e.target.id]: e.target.value,
      };
    });
  }

  function handleBlur(e: any) {
    e.persist();
    setTouched((cur: any) => {
      return { ...cur, [e.target.id]: true };
    });
  }

  function getErrors(formData: any) {
    const formValue = formData.accountId.trim();
    const result = {
      accountId: "",
    };
    if (status === FORM_STATUS.COMPLETED) return result;
    if (!formValue) {
      result.accountId = "Please enter a patient't account Id.";
    } else if (!/{?\w{8}-?\w{4}-?\w{4}-?\w{4}-?\w{12}}?/i.test(formValue)) {
      result.accountId = "Your patient's account Id is Invalid.";
    }

    return result;
  }

  //Derived state
  const errors = getErrors(formData);
  const isValid = Object.values(errors).every((x) => x === null || x === "");

  const onSubmit = () => {
    if (requestComplete) {
      if (accountId) {
        dispatch(getPatients(accountId, true));
      }
    }

    if (isValid) {
      setStatus(FORM_STATUS.SUBMITTING);
      setIsLoading(true);
      const formValue = formData.accountId.trim();
      //TODO: "isAuthorizedByPatient" is hardcoded to true here - need to find out what determines this
      healthcareProfessionalService
        .registerPatient(accountId, true, formValue)
        .then((response: any) => {
          setStatus(FORM_STATUS.COMPLETED);
          setFormData(defaultState);
          setRequestComplete(true);
          setStatusMessage(response.message);
        })
        .catch((error: any) => {
          setStatusMessage(error);
          //setRequestComplete(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setStatus(FORM_STATUS.SUBMITTED);
    }
  };

  const addPatientDialogContent = (isValid: boolean, isLoading: boolean) => {
    return (
      <>
        <Typography>
          To add a patient, please enter an accountId below
        </Typography>
        <Grid container direction="row">
          <Grid item xs={true}>
            <TextField
              margin="dense"
              type="text"
              fullWidth
              variant="standard"
              required
              id="accountId"
              label="Account Id"
              name="account id"
              value={formData.accountId}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={status === FORM_STATUS.COMPLETED ? true : false}
              helperText={
                statusMessage ||
                ((touched.accountId || status === FORM_STATUS.SUBMITTED) &&
                  errors.accountId)
              }
              error={
                statusMessage !== "" ||
                ((touched.accountId !== "" ||
                  status === FORM_STATUS.SUBMITTED) &&
                  errors.accountId !== "")
              }
            />
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingButton
              onClick={() => onSubmit()}
              disabled={!isValid}
              loading={isLoading}
              variant="contained"
              color="primary"
            >
              Add
            </LoadingButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const addPatientDialogResultMessage = () => {
    return (
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography>{statusMessage}</Typography>
      </Grid>
    );
  };

  return (
    <Box>
      <BackButton />

      <Box sx={{ ml: "10px" }}>
        <Typography variant="h4" component="h4">
          Add Patient
        </Typography>

        <Box
          sx={{
            mt: "10px",
            width: "fit-content",
            padding: "10px 20px 10px 20px",
            background: theme.palette.common.white,
          }}
        >
          <Typography>To get patient data, you can</Typography>
          <Typography sx={{ mt: "8px" }}>
            Ask your patient to scan the below QR code in their&nbsp;
            {theme.productNames.app}&nbsp;&#40;under Settings &gt; Share my data
            with HCP&#41;.
          </Typography>

          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={1}
            sx={{
              mt: "8px",
            }}
          >
            <Grid item xl={6} md={6} sm={12} xs={12}>
              <QRCode value={accountId} size={192} />
            </Grid>
            <Grid item xl={6} md={6} sm={12} xs={12}>
              <Typography variant="subtitle1" component="div">
                {accountId}
              </Typography>
            </Grid>
          </Grid>
          <Divider
            sx={{
              mt: "8px",
              mb: "8px",
              "&::before, &::after": {
                borderColor: (theme) => theme.palette.primary.main,
              },
            }}
          >
            OR
          </Divider>
          <Typography>
            Ask your patient to share their Unique Account ID, &#40;accessible
            via the {theme.productNames.app}&#41; and add it below:
          </Typography>

          <Box
            sx={{
              mt: "8px",
            }}
          >
            {requestComplete
              ? addPatientDialogResultMessage()
              : addPatientDialogContent(isValid, isLoading)}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddPatientPage;
