import * as React from "react";
import { useEffect, useState } from "react";
import {
  angleToPosition,
  AngleDescription,
  valueToAngle,
} from "./circularGeometry";
import { arcPathWithRoundedEnds } from "./svgPaths";
import { ColorStop } from "./colorStop";
interface Props {
  size: number;
  minValue: number;
  maxValue: number;
  startAngle: number;
  endAngle: number;
  angleType: AngleDescription;
  value: number;
  unit?: string;
  range?: Array<number>;
  isAnimated?: boolean;
  colorStops?: Array<ColorStop>;
}

export const RadialIndicator: React.FC<Props> = ({
  size = 200,
  minValue = 0,
  maxValue = 100,
  startAngle = 0,
  endAngle = 360,
  angleType = {
    direction: "cw",
    axis: "-y",
  },
  value = 0,
  unit = "",
  range = [],
  isAnimated = false,
  colorStops,
}) => {
  const [animatedValue, setAnimatedValue] = useState(0);

  const handleSize = 10;
  const trackWidth = 12;
  const trackInnerRadius = size / 2 - trackWidth - 25;
  const handle1Angle = valueToAngle({
    value: animatedValue,
    minValue,
    maxValue,
    startAngle,
    endAngle,
  });

  useEffect(() => {
    if (isAnimated) {
      const animationDuration = 1000; // Animation duration in milliseconds
      const startTime = performance.now();
      const animate = (currentTime: any) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / animationDuration, 1);
        setAnimatedValue(value * progress);
        if (progress < 1) {
          requestAnimationFrame(animate);
        }
      };
      requestAnimationFrame(animate);
    } else {
      setAnimatedValue(value);
    }
  }, [value, isAnimated]);

  const handle1Position = angleToPosition(
    { degree: handle1Angle, ...angleType },
    trackInnerRadius + trackWidth / 2,
    size
  );

  const arcPath = arcPathWithRoundedEnds({
    startAngle,
    endAngle,
    angleType,
    innerRadius: trackInnerRadius,
    thickness: trackWidth,
    svgSize: size,
    direction: angleType.direction,
  });

  function createConicGradient(
    startAngle: number,
    endAngle: number,
    thickness: number,
    innerRadius: number,
    size: number,
    colorStops?: Array<ColorStop>
  ) {
    const defaultColorStops = [
      { color: "black", position: 0 },
      { color: "black", position: 100 },
    ];
    const stops = colorStops || defaultColorStops;

    const arcLength = 2 * Math.PI * (innerRadius + thickness / 2);
    const buttAngle = (360 * (thickness / 2)) / arcLength;
    const adjustedStartAngle = startAngle - buttAngle;
    const startDeg = 180 + adjustedStartAngle;

    const gradientStops = stops
      .map((stop) => {
        const position =
          stop.position === 0
            ? 0
            : stop.position === 100
            ? 2 * buttAngle + (endAngle - startAngle)
            : buttAngle + (stop.position / 100) * (endAngle - startAngle);

        return `${stop.color} ${position}deg`;
      })
      .join(", ");

    return `conic-gradient(from ${startDeg}deg, ${gradientStops})`;
  }

  const gradient = createConicGradient(
    startAngle,
    endAngle,
    trackWidth,
    trackInnerRadius,
    size,
    colorStops
  );

  const uniqueId = `arcClip-${Math.random().toString(36).substr(2, 9)}`;

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <defs>
        <clipPath id={uniqueId}>
          <path d={arcPath} />
        </clipPath>
      </defs>
      <foreignObject width="100%" height="100%" clipPath={`url(#${uniqueId})`}>
        <div
          style={{
            width: "100%",
            height: "100%",
            background: gradient,
          }}
        >
          <svg width="100%" height="100%" viewBox={`0 0 ${size} ${size}`}>
            <path id="arc" d={arcPath} fill="none" />
          </svg>
        </div>
      </foreignObject>
      <g id="range">
        {range &&
          range.map((value, key) => {
            const sliceAngle = valueToAngle({
              value: value,
              minValue,
              maxValue,
              startAngle,
              endAngle,
            });
            const sliceAngleToPosition = angleToPosition(
              { degree: sliceAngle, ...angleType },
              trackInnerRadius + trackWidth / 2 + 22, // Adjusted position
              size
            );
            return (
              <g key={key}>
                <text
                  x={sliceAngleToPosition.x}
                  y={sliceAngleToPosition.y}
                  fill="#727577"
                  dominantBaseline="middle"
                  textAnchor="middle"
                >
                  {value}
                </text>
                <line
                  x1={sliceAngleToPosition.x + 11}
                  y1={sliceAngleToPosition.y + 11}
                  x2={sliceAngleToPosition.x + 8}
                  y2={sliceAngleToPosition.y + 8}
                  transform={`rotate(${sliceAngle + 225} ${
                    sliceAngleToPosition.x
                  } ${sliceAngleToPosition.y}) translate(0 0)`}
                  stroke="#727577"
                />
              </g>
            );
          })}
      </g>
      <g id="progressThumb">
        <filter id="handleShadow" x="-80%" y="-80%" width="16" height="16">
          <feOffset result="offOut" in="SourceGraphic" dx="0" dy="0" />
          <feColorMatrix
            result="matrixOut"
            in="offOut"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="5" />
          <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
        </filter>
        <circle
          r={handleSize}
          cx={handle1Position.x}
          cy={handle1Position.y}
          stroke="black"
          strokeWidth="12"
          fill="none"
          filter="url(#handleShadow)"
        />
        <circle
          r={handleSize}
          cx={handle1Position.x}
          cy={handle1Position.y}
          stroke="white"
          strokeWidth="11"
          fill="none"
          filter="url(#handleShadow)"
        />
      </g>
      <g>
        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontWeight="bold"
          fill="black"
          fontSize={size >= 199 ? "2.5em" : "1.5em"}
          fontFamily="Montserrat"
        >
          {animatedValue ? animatedValue : ""}
        </text>
        <text
          x="50%"
          y="65%"
          dominantBaseline="middle"
          textAnchor="middle"
          fill="black"
          fontSize={size >= 199 ? "1em" : "0.7em"}
          fontFamily="Montserrat"
        >
          {unit ? unit : ""}
        </text>
      </g>
    </svg>
  );
};
