export enum PassiveParameterTypeEnum {
  AverageStrideLength = 1,
  AverageAngle = 2,
  TotalDistance = 3,
  AverageWalkingSpeed = 4,
  AverageAsymmetry = 5,
  AverageCadence = 6,
  AverageVariability = 7,
  AverageSwing = 8,
  AverageStance = 9,
  AverageCycleDuration = 10,
  AverageSwingSpeed = 11,
  AveragePeakAngleVelocity = 12,
  TotalSteps = 13,
  AverageDoubleSupport = 14,
  InsoleUsage = 15,
  FallRiskIndex = 16,
  Latitude = 17,
  Longitude = 18,
  BatteryLevel = 19,
  Compliance = 20,
}
