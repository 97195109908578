export const errorMessages = {
  ACC_100: "Account not found",
  ACC_101: "The timezone is the same as the user currently has.",
  PTNT_100: "Patient does not exist",
  PTNT_101: "Patient already registered with Healthcare Professional",
  PTNT_102: "Healthcare professional is already approved",
  PTNT_103: "Healthcare professional was not registered before being approved",
  PTNT_104: "Healthcare professional is already revoked",
  PTNT_105: "Healthcare professional was not registered before being revoked",
  PTNT_106: "Patient is not registered with healthcare professional",
  DEFAULT: "An Error occurred",
};
