import { Grid } from "@mui/material";
import * as React from "react";
import { RadialIndicator } from "../../../documented/RadialIndicator";
import { PassiveParameterTypeEnum } from "../../../../model/passive/enum/passiveParameterTypeEnum";
import getRadialConfig from "../../../documented/RadialIndicator/radialConfig";

interface Props {
  minValue: number;
  maxValue: number;
  range: Array<number>;
  value: number;
  unit: string;
}

export const AverageWalkingSpeedPanel: React.FC<Props> = ({
  minValue,
  maxValue,
  range,
  value,
  unit,
}) => {
  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <RadialIndicator
            {...getRadialConfig(PassiveParameterTypeEnum.AverageWalkingSpeed)}
            size={200}
            value={value}
            unit={unit}
          />
        </Grid>
      </Grid>
    </>
  );
};
