import { useEffect, useState } from "react";
import { Patient } from "../../model/patient/patient";
import { Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { Gender } from "../../model/patient/gender";
import { usePersistentStorageValue } from "../../helpers/hooks/PersistentStorage";
import { ageFromDate } from "../../helpers/utilityFunctions/utilityFunctions";

interface PatientNameProps {
  patient?: Patient;
  patientKey: string;
  display?: string;
  variant?: Variant;
  component?: React.ElementType;
}

const defaultPatient: Patient = {
  accountGuid: "unknown",
  accountTypeId: 1,
  alertLevel: 0,
  currentCompliancePeriod: null,
  dateOfBirth: "unknown",
  email: "unknown",
  firstName: "unknown",
  gender: Gender.Unknown,
  insoleLastUsageDate: "unknown",
  lastName: "unknown",
  verified: "unknown",
  weight: 0,
  totalDaysOfInsoleUsageCurrentMonth: 0,
  totalDaysOfInsoleUsageLastMonth: 0,
};

function PatientValue({
  patient,
  patientKey,
  display,
  variant,
  component,
}: PatientNameProps): JSX.Element {
  const [computedValue, setComputedValue] = useState<string>("");
  const [selectedPatient, setSelectedPatient] =
    useState<Patient>(defaultPatient);
  const [persistedPatient] = usePersistentStorageValue(
    "selectedPatientFromPatientsTable",
    defaultPatient
  );

  const variantType = variant ? variant : "h4";
  const componentType = component ? component : "h4";

  useEffect(() => {
    setSelectedPatient(patient ? patient : persistedPatient);
  }, [patient, persistedPatient]);

  useEffect(() => {
    const value =
      patientKey === "name"
        ? `${selectedPatient.firstName} ${selectedPatient.lastName}`
        : patientKey === "gender"
        ? Gender[selectedPatient.gender]
        : patientKey === "dateOfBirth"
        ? ageFromDate(selectedPatient.dateOfBirth).toString()
        : selectedPatient[patientKey as keyof Patient];

    setComputedValue(value as string);
  }, [patientKey, selectedPatient]);

  return (
    <Typography
      variant={variantType}
      component={componentType}
      display={display}
    >
      {computedValue}
    </Typography>
  );
}

export default PatientValue;
